import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import Portal from '../atoms/Portal/Portal';

const Overlay = styled.div<CheckoutPopupProps>`
  background-color: ${({ theme }) => theme.transparentColors.black50};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  ${({ zIndex }) =>
    typeof zIndex !== 'undefined' &&
    `
    z-index: ${zIndex};
  `}
`;

type CheckoutPopupProps = {
  onClose?: () => void;
  zIndex?: number;
};

class CheckoutPopup extends PureComponent<
  PropsWithChildren<CheckoutPopupProps>
> {
  state = { isModalLoaded: false };

  static defaultProps = {
    onClose: () => {},
  };

  body: HTMLElement;
  overlay: React.RefObject<HTMLDivElement> = React.createRef();

  handleClickOutside = e => {
    if (this.overlay.current.childNodes[0].contains(e.target)) {
      return;
    }

    this.state.isModalLoaded && this.props.onClose();

    if (!this.state.isModalLoaded) this.setState({ isModalLoaded: true });
  };

  handleEscClose = e => {
    const ESC = 27;

    if (e.keyCode === ESC) {
      this.props.onClose();
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
    document.addEventListener('keydown', this.handleEscClose, false);
    this.body = document.getElementsByTagName('body')[0];
    this.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
    document.removeEventListener('keydown', this.handleEscClose, false);
    this.body.style.overflow = '';
  }

  render() {
    const { children } = this.props;

    return (
      <Portal node="popup">
        <Overlay ref={this.overlay} zIndex={this.props.zIndex}>
          {children}
        </Overlay>
      </Portal>
    );
  }
}

export default CheckoutPopup;
